body {
    font-family: "proxima-nova",sans-serif;
    margin: 0;
}

.clear-float {
    clear:both;
}

img.display-left {
    float:left;
    margin-right:20px;
    margin-bottom:10px;
    max-width: 50%;
}

img.display-right {
    float:right;
    margin-left:20px;
    margin-bottom:10px;
    max-width: 50%;
}

.yellow {
    color: #F99E1C;
    font-weight: 700;
}

.navy {
    color: #002D3D;
}
.blue{
    color: #024b65;
}
.pdfbtn {
    position: relative;
    display: inline-block;
    text-align: center;
    vertical-align: bottom;
    color: #F99E1C;
    font-weight: 700;
    margin: 10px;
    max-width: 100%;
}
.pdfbtn:before {
    content: "";
    position: relative;
    background-image: url('../assets/img/pdf.svg');
    display: block;
    width: 50px;
    height: 50px;
    background-position: center;
    background-repeat: no-repeat;
    margin: 0 auto;
}
.button {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    max-width: 100%;
    color: #002D3D;
    background-color: #FFF;
    box-sizing: border-box;
    transition: all 0.3s ease;
    /* border-radius: 10px; */
    border: 1px solid #F9A833;
    padding: 10px 15px;
    font-size: 10px 15px;
    cursor: pointer;
}
.button:hover {
    color: #FFF;
    background-color: #F9A833;
}

@media only screen and (max-width:760px){
    img.display-left,
    img.display-right {
        float: none;
        display: block;
        margin: 20px 0;
        max-width: 100%;
    }
}